.container {
  height: 100vh;
  padding: 30px 5px;
  margin: 0 auto;
  max-width: 600px;
}
@media (max-width: 783px) {
  .container {
    height: 100vh;
    padding: 30px 5px 5px 105px;
    margin: 0 auto;
    max-width: 600px;
  }
}

.headerText {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.title {
  flex: 1 1 600px;
  margin-right: 10px;
}

.description {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.descriptionText {
  flex: 1 1 600px;
  margin-right: 10px;
}

.titleColorPicker {
  font-weight: bold;
  font-size: 24px;
}
.colorContainer {
  margin-bottom: 10px;
}

.title {
  font-size: 23px;
  font-weight: bold;
}

.rowContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  justify-content: space-between;
}

@media (max-width: 500px) {
  .rowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .block {
    padding-left: 10px;
  }
}

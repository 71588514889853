#basic-nav-dropdown {
  color: white;
}
html,
body {
  box-sizing: border-box;
  margin: 0px 0px;
  font-family: "Montserrat", sans-serif;
}
html {
  min-height: 100vh;
}
body {
  min-height: 100vh;
}
#root {
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
}
.App {
  min-height: 100vh;
}
body {
  scrollbar-width: thin;
  scrollbar-color: #888888 #f0f0f0;
}

/* Стили полосы прокрутки */
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

body::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 30px;
}

input:focus {
  outline: none;
}
input {
  border: none;
}

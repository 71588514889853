.sidebar {
  position: fixed;
  z-index: 99;
  background-color: #0a192f;
  width: 350px;
  height: 100vh;
  padding: 20px;
  color: #f6f5ff;
  transition: all 0.6s ease;
}
.sidebar.close {
  transform: translateX(-75%);
}
.sidebar.close .sidebar-header__icon {
  rotate: 180deg;
}
.sidebar-header__row {
  display: flex;
  align-items: center;
  font-size: 30px;
  margin-bottom: 30px;
}
.sidebar-header__icon {
  transition: all 0.6s ease;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
}

.sidebar-header__icon:hover {
  background-color: #112240;
  border-radius: 10px;
}
.sidebar-header__title {
  text-transform: uppercase;
  margin-right: auto;
}
.nav-text {
  font-size: 20px;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}
.nav-text a {
  text-decoration: none;
  color: #f6f5ff;
}
.nav-text:hover {
  transition: all 0.3s ease;
  background-color: #1f4068;
  border-radius: 4px;
}
.nav-text span {
  color: #f6f5ff;
  margin-left: 15px;
}
.sidebar.close .nav-text span {
  display: none;
}
.sidebar.close .nav-text {
  transition: 0.3s ease-in-out;
  transform: translateX(250px);
}
.sidebar.close .nav-text {
  border-radius: 4px;
  display: flex;
  margin: 0px;
  justify-content: center;
  font-size: 25px;
  padding: 10px 30px;
  width: 45px;
}

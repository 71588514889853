.container {
  display: flex;
  justify-content: center;
  padding-left: 100px;
  margin-bottom: 10px;
}
.container > div {
  padding: 35px;
  border-radius: 10px;
  border: 3px solid grey;
}
.addButton {
  padding: 10px;
  background-color: blue;
}
.editBtn {
  transform: translate(-30px, 20px);
  cursor: pointer;
  transition: all 0.3s ease;
}
.editBtn:hover {
  color: brown;
}

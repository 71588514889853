.img {
  max-width: 500px;
  height: auto;
  margin: 0 auto;
}
.img img {
  margin-top: 10px;
  width: 100%;
}

.container {
  height: 100vh;
  padding: 0px 5px 0px 100px;
}

.button {
  max-width: 300px;
  margin: 10px auto;
  padding: 20px;
  background-color: #5761d4;
  text-align: center;
  transition: all 0.3s ease;
  font-size: 24px;
  color: white;
  cursor: pointer;
  border-radius: 10px;
}
.button:hover {
  background-color: #343a7a;
}

@media (max-width: 400px) {
  .button {
    padding: 10px;
  }
}

.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.chose {
  margin-top: 30px;
}

.title {
  text-align: center;
  font-size: 30px;
}

.container {
  height: 100vh;
  padding: 30px 5px;
}
.title {
  display: flex;
  justify-content: center;
  padding-left: 100px;
  font-size: 30px;
}
.addButtonContainer {
  display: flex;
  justify-content: center;
  padding-left: 100px;
  font-size: 30px;
}

.addButton {
  padding: 20px;
  background-color: #0a192f;
  border-radius: 10px;
  color: white;
  transition: all 0.3s ease;
  cursor: pointer;
}

.addButton:hover {
  background-color: #1f4068;
}


.container {
  max-width: 1300px;
  margin: 0 auto;
  min-height: 100vh;
}
.img {
  display: flex;
  flex: 1 1 auto 1300px;
  height: 400px;
  max-width: 100%;
  margin-bottom: 30px;
}
.img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.header {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  word-wrap: break-word;
}
.description {
  text-align: center;
  font-size: 23px;
  font-weight: normal;
  margin-bottom: 20px;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .img {
    display: flex;
    flex: 1 1 auto 300px;
    height: 300px;
    max-width: 100%;
  }
}
@media (max-width: 400px) {
  .img {
    display: flex;
    flex: 1 1 auto 200px;
    height: 300px;
    max-width: 100%;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .button {
  font-size: 24px;
  row-gap: 10px;
  max-width: 600px;
  padding: 20px;
  border-radius: 5px;
  background-color: aqua;
  width: 100%;
  text-align: center;
} */
.wallpaper {
  min-height: 100vh;
  background-size: 100% 100%; /* Растягивать картинку на 100% ширины и высоты элемента */
  background-repeat: no-repeat; /* Запретить повторение фонового изображения */
  background-position: center;
}
